import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'osmb-theme',
            'disable-default-analytics': true,

            // change properties of the selected event component
            'selected-event': {
                buttonClass: 'button',
            },
        });
    });
});
